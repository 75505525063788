<div class="banner-bg">
  <div class="px-2 px-sm-5 center-vertically banner-desc-holder">
    <div class="banner-content">
      <h1 class="banner-sub-heading">
        Seeking workforce recruitment support from WorkSource Centers.
      </h1>
    </div>
  </div>
</div>

<div class="resources-wrp">
  <div class="px-2 px-sm-5">
    <div class="row">
      <div class="col-lg-6">
        <div class="seek-workforce-box mb-2">
          <h5>WARN Act</h5>
          <a
            (click)="
              openPage(
                'WARN Act',
                'https://edd.ca.gov/en/jobs_and_training/Layoff_Services_WARN'
              )
            "
            target="_blank"
            ><img src="assets/icons/icon-globe.svg" alt="" />
            <span>Worker Adjustment and Retraining Notification(WARN)</span>
          </a>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="seek-workforce-box mb-2">
          <h5>Work Sharing Program</h5>
          <a
            (click)="
              openPage(
                'Work Sharing Program',
                'https://edd.ca.gov/en/unemployment/work_sharing_program'
              )
            "
            target="_blank"
            ><img src="assets/icons/icon-globe.svg" alt="" />
            <span>Work Sharing Program(ca.gov) </span></a
          >
        </div>
      </div>
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import { AppMonitorService } from 'src/app/services/app-monitor.service';
import { ProfileService } from 'src/app/services/profile.service';
import { UserInfoService } from 'src/app/services/userclaims/userinfo.service';

@Component({
  selector: 'app-worksource-center',
  templateUrl: './worksource-center.component.html',
  styleUrl: './worksource-center.component.css',
})
export class WorksourceCenterComponent {
  constructor(
    private appMonitorService: AppMonitorService,
    private profileSerice: ProfileService
  ) {}

  openPage(page: string, url: string): void {
    var userClaims = this.profileSerice.getUserClaims();
    this.appMonitorService.trackEvent('page', [
      { url: url },
      { user: `${userClaims.firstName} ${userClaims.lastName}` },
      { email: userClaims.email },
    ]);

    this.appMonitorService.trackPageView(page, url);
    window.open(url, '_blank');
  }
}
